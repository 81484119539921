import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Button from "@material-ui/core/Button";

function About  ()  {

  function handleClick(event){
    event.preventDefault();
    console.log("button clicked");
    var email = "eric@campbelldatascience.com";
    var subject = "Campbell Data Science";
    {/* var emailBody = 'Hi Eric'; */}
    document.location = "mailto:"+email+"?subject="+subject;
}

return(
  <Layout>
    <SEO
      title="About"
      description="Web Design and Marketing. We create beautiful mobile first web apps that are fast and responsive. Built to engage and inform, backed by data science."
    />
    <h1>About</h1>
    {/* <p>
      Welcome to Article Two{" "}
      <Link to="/article-one/">go back to article one</Link>
    </p> */}
    <h2>Hi, I’m Eric.</h2>
    <p>My focus is Web Development, Data Science and Marketing.</p>
    <p>
      I am a React developer primarily and I use Gatsby for multiple page sites.
      The tool is just a means to get the job done so I don’t limit myself to
      one stack.
    </p>
    <h2>A bit of background…</h2>
    <p>
      I started out learning python which gave me an understanding of computer
      science. I learned about data structures first then data visualisation and
      went on to build machine learning algorithms.
    </p>
    <p>
      This was great but the problem was that I couldn’t show anyone without
      loading up python and running the code.
    </p>
    <p>
      Browsers running javascript are everywhere so I learned frontend and back
      end web development. Data structures are the same in different languages
      in my experience, it’s just about learning the syntax.
    </p>
    <p>
      These skills combined let me build applications and create visualisations
      then share them with the world.
    </p>
    {/* <p>
      Link example{" "}
      <a
        href="https://www.gatsbyjs.org/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Gatsby js
      </a>
      . example example
    </p> */}
    <h2>My Skills</h2>
    <p>
      Here’s a list of some of the languages and packages I have worked with;
    </p>
    <h3>Main Programming languages</h3>
    <p>Python – Machine learning and data Science</p>
    <p>Javascript – Frontend(React.js) and backend(node.js) web develoment</p>
    <p>HTML & CSS – Web develoment</p>
    <h3>Some notable packages</h3>
    <p>Node.js – server-side JavaScript runtime environment</p>
    <p>D3js – data visualisation</p>
    <p>Seaborn – data visualisation</p>
    <p>Pandas – data manipulation</p>
    <p>scikit-learn – Machine Learning</p>
    <p>express – Web framework for node</p>
    <p>github – Version control</p>
    <p>passport – OAuth Authentication & Security</p>
    <h3>Databases</h3>
    <p>SQL</p>
    <p>noSQL –MongoDB</p>
    <h3>Statistics and machine learning</h3>
    <p>Regression</p>
    <p>Classification</p>
    <p>Association Rule Learning</p>
    <p>Reinforcement Learning</p>
    <p>Deep Learning</p>
    <p>Dimensionality Reduction</p>
    <h3>Design and media</h3>
    <p>Photoshop</p>
    <p>AutoCAD</p>
    <p>Revit</p>
    <p>Premiere pro</p>
    <p>
      Thanks for reading. I am based in Nottingham UK. The nature of this work
      means it can be completely remote so if you have any queries, please get
      in touch. Due to time constrains, I can only offer certain services to
      those interested, currently;
    </p>
    <p>
      Set up and maintain landing pages for lead generation with analytics
      integration
    </p>
           <p>Get in touch if you have any questions, need advice on sharing anything on this site, setting up your own visualisations and more.</p>
           
           <div style={{textAlign: "center"}}>
           <Button
            variant="contained"
            color="primary"
            href="#contained-buttons"
            disableElevation
            style={{
              width: "25ch",
              backgroundColor: "#D8564A", 
              marginBottom: "20px"
            }}
            onClick={handleClick}
          >
            CONTACT
          </Button>  
          </div>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)
}

export default About
